import React from 'react';
import ReactQr from 'react-awesome-qr'
import LOGOIMG from '../../koyn.png';


const CampaignSharing = ({
  campaign,
  loading,
  handleOpen,
}) => (
      <div>
        <h4>Share on Social Media</h4>
        {loading && <div>Loading ...</div>}

        {campaign && (
          <table>
            <tr>
              <td><div onClick={handleOpen} class="shareUrl"><span>Copy to Clipboard</span>{campaign.shareUrl}</div></td>
            </tr>
            <tr>
              <td>
              <div class="airdropBG">
                <ReactQr text={campaign.shareUrl} logoMargin={0} logoScale={0.1} logoSrc={LOGOIMG} backgroundColor={"#FFFFFF"} size={800} margin={10} colorDark={"#00696b"} dotScale={0.5}/>
              </div>
              </td>
            </tr>
          </table>
        )}
        </div>
);

export default CampaignSharing;
