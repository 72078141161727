import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Vibrant from 'node-vibrant';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const SettingsPage = () => (
  <Grid container xs={12} md={12} lg={12}>
    <Grid container>
      <h2>Account</h2>
    </Grid>
    <Paper className="papperSpace">
        <Grid item xs={12} md={12} lg={12}>
          <Grid item xs={12} md={12} lg={12}>
            <SettingsForm />
          </Grid>
        </Grid>
      </Paper>
  </Grid>
);

const INITIAL_STATE = {
  name: '',
  image: '',
  downloadURL: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const rgbToHex = function (rgb : any) {
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
       hex = "0" + hex;
  }
  return hex;
};

class SettingsFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    console.log("test response ");
    event.preventDefault();

    const { name, downloadURL } = this.state;
    let brandId = "vlMOE1hmpHergMrlXmaD";

    console.log("test response here");

    console.log(downloadURL);

    this.doGetPalette(downloadURL).then(palette => {
      console.log(palette);
      return this.doGetHex(palette);
    }).then(background => {
      console.log(background);
      this.doUpdateBrand(brandId, background, name, downloadURL);
    }).then(() => {
      this.setState({ ...INITIAL_STATE });
      this.props.history.push(ROUTES.CAMPAIGNS);
    }).catch(error => {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
    });
  };

  doGetPalette = (image) => {
    return Vibrant.from(image).getPalette();
  };

  doGetHex = (palette) => {
    const HexA = rgbToHex(palette.Vibrant._rgb[0]);
    const HexB = rgbToHex(palette.Vibrant._rgb[1]);
    const HexC = rgbToHex(palette.Vibrant._rgb[2]);
    return "#" + HexA + HexB + HexC + "FF";
  };

  doLoadPhoto = (file) => {
    console.log('File Name vlMOE1hmpHergMrlXmaD');
    return this.props.firebase.storage.ref(`brands/vlMOE1hmpHergMrlXmaD`).put(file)
    .then(function(snapshot) {
      console.log('Uploaded a blob or file!');
      return snapshot;
    });
  };

  doDownloadUrl = (snapshot) => {
    return snapshot.ref.getDownloadURL();
  }

  doUpdateBrand = (brandId, background, name, downloadUrl) => {
    this.props.firebase.brand(brandId).set({
      name: name,
      image: downloadUrl,
      updatedAt: this.props.firebase.fieldValue.serverTimestamp(),
      background: background,
      isActive : true
    }, {merge: true
    });
  };

  doGetBrand = (brandId) => {
    return this.props.firebase.brand(brandId).get();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onImageLoad = event => {
    this.setState({ [event.target.name]: event.target.value });
    var file = event.target.files[0];
    this.doLoadPhoto(file).then(snapshot => {
      console.log(snapshot)
      return this.doDownloadUrl(snapshot);
    }).then(downloadURL => {
      console.log("downloadURL", downloadURL);
      this.setState({downloadURL : downloadURL});
    //   return Vibrant.from(downloadURL).getPalette();
    // }).then(palette => {
    //   console.log(palette);
    }).catch(error => {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
    });
  }

  render() {
    const {
      name,
      image,
      error,
    } = this.state;

    const isInvalid =
      name === '' ||
      image === '';

    return (
      <form onSubmit={this.onSubmit}>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Brand Name:"
            secondary="What name would you like to use"
          />
          <TextField
            name="name"
            value={name}
            onChange={this.onChange}
            type="text"
            placeholder="Brand Name"
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Brand Image:"
            secondary="Upload your brand logo that you would like to use."
          />
          <TextField
            name="image"
            value={image}
            onChange={this.onImageLoad}
            type="file"
            placeholder="Brand Image"
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary=""
            secondary=""
          />
          <Button disabled={isInvalid} variant="contained" type="submit">Save</Button>
        </ListItem>
        <Divider variant="middle" />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Sign Out:"
            secondary="Click to sign out"
          />
          <Button onClick={() => { this.props.firebase.doSignOut(); }} variant="contained" color="primary">
            Sign Out
          </Button>
        </ListItem>
      </List>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SettingsForm = withRouter(withFirebase(SettingsFormBase));

export default SettingsPage;

export { SettingsForm };
