import React from 'react';
import { Link } from 'react-router-dom';

import Footer from '../Footer';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IMG from '../../koyn_logo.png';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div>
    <Grid>
      <img alt="" src={IMG} className="logo" />
    </Grid>
    <ul className="navContainer">
      <li  className="navLink">
        <Link to={ROUTES.NEWCAMPAIGN} className="navColor">
          <Button size="medium" variant="contained" color="primary">
            New Campaign
          </Button>
        </Link>
      </li>
      <li  className="navLink">
        <Link to={ROUTES.CAMPAIGNS} className="navColor navigationLink ">Campaigns</Link>
      </li>
      <li  className="navLink">
        <Link to={ROUTES.SUBSCRIBERS} className="navColor navigationLink ">Customers</Link>
      </li>
      <li  className="navLink">
        <Link to={ROUTES.ACCOUNT} className="navColor navigationLink ">Settings</Link>
      </li>
      <li className="navLink">
      </li>
    </ul>
    <div className="bottom">
      <div className="bottomFooter">
        <p>
          <a href="https://www.koyn.co/terms" className="footerLink">
            Legal 
          </a> 
          <a href="#" className="footerLink">
             Contact Us
          </a>
        </p>
      </div>
      <Footer />
    </div>
  </div>
);

// <li class="navLink">
//   <Link to={ROUTES.LANDING} class="navColor">Landing</Link>
// </li>
// <li  class="navLink">
//   <Link to={ROUTES.HOME} class="navColor">Home</Link>
// </li>

const NavigationNonAuth = () => (
  <ul>
    <li>
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </li>
  </ul>
);

export default Navigation;
