import React from 'react';
import Typography from '@material-ui/core/Typography';

const Footer = () => (
    <Typography className="footer footerLink" variant="body2" color="textSecondary" align="center">
      <a href="https://www.koyn.co/" className="footerLink">
      {'©'}  Koyn Co. {new Date().getFullYear()}
      </a>
    </Typography>
);

export default Footer;
