import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const LogInPage = () => (
  <div>
    <h2>Loging In ...</h2>
    <LogInGoogle />
  </div>
);

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;


class LogInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };

    const query = new URLSearchParams(this.props.location.search);
    const cToken = query.get('cToken')

    this.props.firebase
      .doSignInWithCustomToken(cToken)
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.AUTH);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });
  }

  render() {
    const { error } = this.state;

    return (
      <div>
        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const LogInGoogle = compose(
  withRouter,
  withFirebase,
)(LogInGoogleBase);

export default LogInPage;

export { LogInGoogle };
