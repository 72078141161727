import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import zoid from 'zoid';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';


const Iframe = zoid.create({
    tag: 'my-login-component',
    url: 'https://www.koyn.co/',
    dimensions: {
        width: '400px',
        height: '200px'
    },
});

export default Iframe;
