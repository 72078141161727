import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import SubscribersList from './SubscribersList';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class Subscribers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      subscribers: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForCampaigns();
  }

  onListenForCampaigns = () => {
    this.setState({ loading: true });

 // .orderBy('createdAt', 'desc')
    this.unsubscribe = this.props.firebase
      .balances()
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let subscribers = [];
          snapshot.forEach(doc => {
            subscribers.push({ ...doc.data(), bid: doc.id });

            // this.props.firebase.user(doc.data().user).onSnapshot(snap => {
            //   console.log(snap.data());
            //   if (snap.size) {
            //     subscribers.push({ ...doc.data(), uid: doc.id, user: snap.data(), userId: snap.id });
            //   }
            // })
          })
          console.log(subscribers);
          this.setState({
            subscribers: subscribers.reverse(),
            loading: false
          })

        } else {
          this.setState({ subscribers: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForCampaigns,
    );
  };

  render() {
    const { subscribers, loading, } = this.state;

    return (

      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container>
            <Grid container>
              {subscribers && (<h2>Customers</h2>)}
            </Grid>
            <Paper className="papperSpace">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
              {loading && <div>Loading ...</div>}

              {subscribers && (
                <SubscribersList
                  authUser={authUser}
                  subscribers={subscribers}
                />
              )}

              {!subscribers && <div>There are no subscribers ...</div>}
              </Grid>
            </Grid>
            </Paper>
        </Grid>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Subscribers);
