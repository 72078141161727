import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import UniqueId from 'uniqid';
import Axios from 'axios';

import { withFirebase } from '../Firebase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const NewCampaignPage = () => (
  <Grid container xs={12} md={12} lg={12}>
    <Grid container>
      <h2>New Campaign</h2>
    </Grid>
    <Paper className="papperSpace">
        <Grid item xs={12} md={12} lg={12}>
          <Grid item xs={12} md={12} lg={12}>
            <NewCampaignForm />
          </Grid>
        </Grid>
      </Paper>
  </Grid>
);

const INITIAL_STATE = {
  name: '',
  discount: '',
  card: '',
  message: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class NewCampaignFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    console.log("test response ");
    event.preventDefault();

    const { name, discount, card, message } = this.state;
    const airdropId = UniqueId();
    let brandId = "vlMOE1hmpHergMrlXmaD";
    let shareUrl = "";

    console.log("test response here");

    console.log(discount);
    var disc = Number(discount.replace(".", ""));
    console.log(disc);

    this.doCreateShortLink(airdropId).then(response => {
      console.log(response.data.shortLink);
      shareUrl = response.data.shortLink;
      return this.doGetBrand(brandId);
    }).then(brand => {
      console.log(brand.data());
      return this.doCreateCampaign(airdropId, name, disc, card, message, shareUrl, brandId, brand.data());
    }).then(() => {
      this.setState({ ...INITIAL_STATE });
      this.props.history.push("/campaign/"+airdropId);
    }).catch(error => {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
    });
  };
 // 962194608
 
  doCreateShortLink = (airdropId) => {
    console.log("airdropId", airdropId);
    const body = {
          "dynamicLinkInfo": {
            "domainUriPrefix": "https://koyn.me",
            "link": "https://app.koyn.co/airdrop/" + airdropId,
            "iosInfo": {
              "iosBundleId": "com.tokens.rewards",
              "iosAppStoreId": ""
            },
            "navigationInfo": {
              "enableForcedRedirect": false,
            },
            "socialMetaTagInfo": {
              "socialTitle": "Tap for a reward 🎁",
              "socialDescription": "",
              "socialImageLink": "https://firebasestorage.googleapis.com/v0/b/rewards-f5ab8.appspot.com/o/100.jpeg?alt=media&token=f0360665-edd8-4c95-9166-f4f1efc8e914"
            }
          },
          "suffix": {
            "option": "SHORT"
          }
    };
    const dynamicLinkURL = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBY4g4bRBT1T3-FZNSf7UPcsyamA9Ly6hM"
    return Axios.create({
      timeout: 1000
    }).post(dynamicLinkURL, body);
  }

  doGetBrand = (brandId) => {
    return this.props.firebase.brand(brandId).get();
  }

  doCreateCampaign = (airdropId, name, discount, card, message, shareUrl, brandId, brand) => {
    this.props.firebase.campaign(airdropId).set({
      name: name,
      claimed: {user : "123"},
      counter: 0,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
      isActive: true,
      updatedAt: this.props.firebase.fieldValue.serverTimestamp(),
      present: {
          amount: Number(discount),
          asset: {
              background : brand.background,
              brandId : brandId,
              image : brand.image,
              name : brand.name
          },
          card: card,
          message: message
      },
      airdropId: airdropId,
      shareUrl: shareUrl
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      name,
      discount,
      card,
      message,
      error,
    } = this.state;

    const isInvalid =
      name === '' ||
      discount === 0 ||
      card === '' ||
      message === '';

    return (
      <form onSubmit={this.onSubmit}>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Campaign Name:"
            secondary="Enter a name for this campaign"
          />
          <FormControl>
            <Input
              name="name"
              value={name}
              onChange={this.onChange}
              placeholder="Campaign Name"
              helperText="Incorrect entry."
            />
          </FormControl>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Discount Amount:"
            secondary="Enter a discount amount to give away"
          />
          <FormControl>

            <CurrencyTextField
             placeholder="Discount Amount"
             variant="standard"
             name="discount"
             value={discount}
             currencySymbol="$"
             minimumValue="0"
             outputFormat="string"
             decimalCharacter="."
             digitGroupSeparator=","
             onChange={this.onChange}
             />
          </FormControl>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Image:"
            secondary="Select an Image"
          />
          <FormControl>
            <Input
              name="card"
              value={card}
              onChange={this.onChange}
              placeholder="Image"
              helperText="Incorrect entry."
            />
          </FormControl>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Message:"
            secondary="Enter a message"
          />
          <FormControl>
            <Input
              name="message"
              value={message}
              onChange={this.onChange}
              placeholder="Message"
              helperText="Incorrect entry."
            />
           </FormControl>
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary=""
            secondary=""
          />
          <Button disabled={isInvalid} variant="contained" type="submit">Create Campaign</Button>
        </ListItem>
      </List>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const NewCampaignForm = withRouter(withFirebase(NewCampaignFormBase));

export default NewCampaignPage;

export { NewCampaignForm };
