import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Grow from '@material-ui/core/Grow';
import CampaignDetails from './CampaignDetails';
import CampaignSharing from './CampaignSharing';
import CampaignAnalytics from './CampaignAnalytics';

class Campaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      campaign: null,
      analytics: null,
      open: false,
      Transition: Grow,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.campaign) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .campaign(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          campaign: snapshot.data(),
          loading: false,
          analytics: {clicks: 0},
          open: false,
          Transition: Grow,
        });
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        };
        // fetch('https://merchant.koyn.co/v1/campaign/'+this.props.match.params.id, requestOptions).then(res => {
        //   return res.json();
        // })
        // .then(data => {
        //   console.log(data.data.analytics);
        //   const analy = data.data.analytics;
        //   console.log(analy);
        //   if(analy && analy.linkEventStats){
        //     console.log(analy.linkEventStats[0]);
        //     const linkClicks = analy.linkEventStats[0].count;
        //     this.setState({
        //       analytics: {clicks: linkClicks}
        //     });
        //   }else{
        //     this.setState({
        //       analytics: {clicks: 0}
        //     });
        //   }
        // })
        // .catch(err => {
        //     console.log("err");
        //     console.log(err);
        // });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  handleOpen = event => {
    this.setState({
      open: true
    });
    navigator.clipboard.writeText(this.state.campaign.shareUrl);
  }

  handleClose = event => {
    this.setState({
      open: false
    });
  }

  render() {
    const { campaign, loading, open, analytics } = this.state;
    return (

      <AuthUserContext.Consumer>

        {authUser => (
            <Grid container>
              <Grid container>
                {campaign && (<h2>{campaign.name}</h2>)}
              </Grid>
              <Paper className="papperSpace">
                  <Grid container spacing={3}>
                  <Grid item xs={6} md={6} lg={6}>
                    <CampaignSharing
                      loading={loading}
                      campaign={campaign}
                      handleOpen={this.handleOpen}
                    />
                  </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <CampaignDetails
                        loading={loading}
                        campaign={campaign}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <CampaignAnalytics
                        loading={loading}
                        campaign={campaign}
                        analytics={analytics}
                      />
                    </Grid>
                  </Grid>
                  <Snackbar
                    anchorOrigin={'top center'}
                    open={open}
                    onClose={this.handleClose}
                    message="I love snacks"
                    key = {'top center'}
                  />
                </Paper>
            </Grid>
          )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Campaign);
