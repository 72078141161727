import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

class SubscribersItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  // {subscriber.user.toString().substr(0, 8)}...

  render() {
    const { subscriber } = this.props;

    return (
      <tr>
          <td>{"Emily S."}</td>
          <td>${subscriber.balance / 100}</td>
          <td>$0</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
    );
  }
}

export default SubscribersItem;
