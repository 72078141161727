import React from 'react';
import Grid from '@material-ui/core/Grid';

const CampaignSharing = ({
  campaign,
  loading,
  analytics,
}) => (
      <div>
        <h4>Campaign Analytics</h4>
        {loading && <div>Loading ...</div>}

        {analytics && (
          <Grid container spacing={3}>
          <Grid item xs={2}>
              <h3>REVENUE</h3>
              <h1>$0</h1>
          </Grid>
            <Grid item xs={2}>
              <h3>SUBSCRIBERS</h3>
              <h1>0</h1>
            </Grid>
            <Grid item xs={2}>
              <h3>CLICKS</h3>
              <h1>{analytics.clicks}</h1>
            </Grid>
            <Grid item xs={2}>
              <h3>ISSUED</h3>
              <h1>0</h1>
            </Grid>
            <Grid item xs={2}>
              <h3>REDEEMED</h3>
              <h1>0</h1>
            </Grid>
            <Grid item xs={2}>
              <h3>AOV</h3>
              <h1>-</h1>
            </Grid>
          </Grid>
        )}
        </div>
);

export default CampaignSharing;
