import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import CampaignsList from './CampaignsList';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class Campaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      campaigns: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForCampaigns();
  }

  onListenForCampaigns = () => {
    this.setState({ loading: true });

 // .orderBy('createdAt', 'desc')
    this.unsubscribe = this.props.firebase
      .campaigns()
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let campaigns = [];
          snapshot.forEach(doc =>
            campaigns.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            campaigns: campaigns.reverse(),
            loading: false,
          });
        } else {
          this.setState({ campaigns: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForCampaigns,
    );
  };

  render() {
    const { campaigns, loading, } = this.state;

    return (

      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container maxWidth="md">
            <Grid container>
              {campaigns && (<h2>Campaigns</h2>)}
            </Grid>
            <Paper className="papperSpace">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
              {loading && <div>Loading ...</div>}

              {campaigns && (
                <CampaignsList
                  authUser={authUser}
                  campaigns={campaigns}
                />
              )}

              {!campaigns && <div>There are no campaigns ...</div>}
              </Grid>
            </Grid>
            </Paper>
        </Grid>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Campaigns);
