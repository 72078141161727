import React from 'react';

import CampaignsItem from './CampaignsItem';

const CampaignsList = ({
  authUser,
  campaigns,
}) => (

  <table>
    <tr>
      <th>CAMPAIGNS</th>
      <th>REVENUE</th>
      <th>ORDERS</th>
      <th>VISITS</th>
      <th>COUPONS ISSUED</th>
    </tr>
      {campaigns.map(campaign => (
        <CampaignsItem
          authUser={authUser}
          key={campaign.uid}
          campaign={campaign}
        />
      ))}
  </table>
);

export default CampaignsList;
