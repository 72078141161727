import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

class CampaignsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { campaign } = this.props;
    const visits = parseFloat(Math.random() * 10000).toFixed(0);
    const issued = parseFloat(visits / 4).toFixed(0);
    const redeemd = parseFloat(issued / 5).toFixed(0);
    const revenue = parseFloat(redeemd * Math.random() * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <tr>
          <td><Link to={ROUTES.CAMPAIGNSHORT+campaign.airdropId} class="navColor">{campaign.name}</Link></td>
          <td>${ revenue }</td>
          <td>{ redeemd }</td>
          <td>{ visits }</td>
          <td>{ issued }</td>
      </tr>
    );
  }
}

export default CampaignsItem;
