import React from 'react';

const CampaignDetails = ({
  campaign,
  loading,
}) => (
      <div>
        <h4>Campaign Details</h4>
        {loading && <div>Loading ...</div>}

        {campaign && (
          <table>
            <tr>
              <th>Name:</th>
              <td>{campaign.name}</td>
            </tr>
            <tr>
              <th>ID:</th>
              <td>{campaign.airdropId}</td>
            </tr>
            <tr>
              <th>Created:</th>
              <td>{campaign.createdAt && campaign.createdAt.toDate().toString().substr(0, 21)}</td>
            </tr>
            <tr>
              <th>Message:</th>
              <td>{campaign.present.message}</td>
            </tr>
            <tr>
              <th>Discount:</th>
              <td>${(campaign.present.amount / 100.00).toFixed(2)}</td>
            </tr>
            <tr>
              <th>Image:</th>
              <td><img alt="" class="displayImg" src={campaign.present.card} /></td>
            </tr>
          </table>
        )}
        </div>
);

export default CampaignDetails;
