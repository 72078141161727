export const DEFAULT = '/*';
export const SIGN_UP = '/signup';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const INIT = '/init';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const CAMPAIGNS = '/campaigns';
export const SETTINGS = '/settings';
export const NEWCAMPAIGN = '/new-campaign';
export const CAMPAIGN = '/campaign/:id';
export const CAMPAIGNSHORT = '/campaign/';
export const PROFILE = '/profile';
export const SUBSCRIBERS = '/customers';

export const MAIN = '/foo';
export const AUTH = '/auth';
export const LOGIN = '/login';
export const SIGN_IN = '/signin';
export const AIRDROP = '/airdrop/:id';
