import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import zoid from 'zoid';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Iframe from '../Iframe';


class Airdrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      brand: null,
      open: false,
      Transition: Grow,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.campaign) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .campaign(this.props.match.params.id)
      .onSnapshot(snapshot => {
        if(!snapshot.data()){
          return;
        }
        const brandId = snapshot.data().present.asset.brandId;
        console.log("brandId");
        console.log(brandId);
        this.props.firebase.brand(brandId).onSnapshot(snapshot => {
          if(!snapshot.data()){
            return;
          }
          var brand = snapshot.data();
          brand.domain = "https://" + brand.domain;
          console.log(brand.domain);
          console.log(brand);

          this.setState({
            brand: brand,
            loading: false,
            open: false,
            Transition: Grow,
          });
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  handleOpen = event => {
    this.setState({
      open: true
    });
    navigator.clipboard.writeText(this.state.campaign.shareUrl);
  }

  handleClose = event => {
    this.setState({
      open: false
    });
  }
//   {brand && (<Iframe />)}
  render() {
    const { brand } = this.state;

    return (

      <Grid container>
        <Grid container>
          {brand && (<h2>{brand.name}</h2>)}
        </Grid>
        <Paper className="papperSpace">
            <Grid container spacing={3}>

            </Grid>
          </Paper>
      </Grid>
    );
  }
}

export default withFirebase(Airdrop);
