import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Navigation from '../Navigation';
import Footer from '../Footer';
import SignInPage from '../SignIn';
import CampaignsPage from '../Campaigns';
import SubscribersPage from '../Subscribers';
import CampaignPage from '../Campaign';
import NewCampaignPage from '../NewCampaign';
import SettingsPage from '../Settings';
import InitPage from '../Init';
import AirdropPage from '../Airdrop'
import ShopifyAuthPage from '../ShopifyAuth';
import LogInPage from '../Login';
import { AuthUserContext } from '../Session';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

// <Route path={ROUTES.AIRDROP} render={async () => {
//   const supported = await Linking.canOpenURL("slack://open?team=123456");
//   if (supported) {
//     await Linking.openURL("slack://open?team=123456");
//   }
// }} />

//<Route exact path={ROUTES.AIRDROP} render={() => (window.location = "https://www.koyn.co/terms")} />
//<Route exact path={ROUTES.AIRDROP} component={AirdropPage} />
//

const App = () => (
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              <AuthContainer authUser={authUser} />
            ) : isMobile ? (
              <MobileContainer />
            ) : ( 
              <NonAuthContainer />
            )
          }
        </AuthUserContext.Consumer>
);

const AuthContainer = ({ authUser }) => (
  <div>
      <main className="mainBackground">
      <Router>
      <Drawer className="demo-drawer backgroundColor demo-navigation"
        variant="permanent"
        >
        <Navigation />
      </Drawer>
        <Container maxWidth="md" className="mainContainter">
            <Route path={ROUTES.CAMPAIGN} component={CampaignPage} />
            <Route path={ROUTES.NEWCAMPAIGN} component={NewCampaignPage} />
            <Route path={ROUTES.ACCOUNT} component={SettingsPage} />
            <Route path={ROUTES.INIT} component={InitPage} />
            <Route path={ROUTES.CAMPAIGNS} component={CampaignsPage} />
            <Route path={ROUTES.SUBSCRIBERS} component={SubscribersPage} />
            <Route exact path={ROUTES.AIRDROP} component={AirdropPage} />
            <Route exact path={ROUTES.AUTH}>
                <Redirect to={ROUTES.CAMPAIGNS} />
            </Route>
        </Container>
        </Router>
      </main>
  </div>
);

const NonAuthContainer = () => (
  <div>
      <main class="mainBackground">
  <Router>
    <Switch>
          <Route path={ROUTES.AIRDROP} component={AirdropPage} />
          <Route path={ROUTES.LOGIN} component={ShopifyAuthPage} />
          <Route path={ROUTES.AUTH} component={LogInPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route render={() => (window.location = "https://www.koyn.co/")} />
    </Switch>
  </Router>
    <Footer />
      </main>
  </div>
);

const MobileContainer = () => (
  <div>
      <main class="mainBackground">
  <Router>
    <Switch>
          <Route component={AirdropPage} />
    </Switch>
  </Router>
    <Footer />
      </main>
  </div>
);

export default withAuthentication(App);
