import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IMG from '../../koyn_logo.png';

const ShopifyAuthPage = () => (
  <Grid container xs={12} md={12} lg={12}>
        <ShopifyAuthForm />
  </Grid>
);

const INITIAL_STATE = {
  name: '',
  error: null,
};


class ShopifyAuthFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {

    event.preventDefault();

    const { name } = this.state;

    this.setState({ ...INITIAL_STATE });

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({ 'shop': name })
    };

    console.log(name);
    fetch('https://merchant.koyn.co/v1/shopify/auth', requestOptions).then(res => {
      return res.json();
    })
    .then(data => {
      console.log(data);
      console.log(data.redirect);
      window.location = data.redirect;
    })
    .catch(err => {
        this.setState({ error: err });
        console.log("err");
        console.log(err);
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

      const {
        name,
        error,
      } = this.state;

      const isInvalid =
        name === '';

      return (

            <Container component="main" maxWidth="sm">
              <CssBaseline />
              <div className="centerPaper">
                <img alt="" src={IMG} class="logo" />
                <Typography component="h1" variant="h5">
                  Sign into your shopify store
                </Typography>
                <br />
                <form noValidate onSubmit={this.onSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Shopify Store Name"
                    autoComplete="name"
                    autoFocus
                    name="name"
                    value={name}
                    onChange={this.onChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isInvalid}
                  >
                    SIGN IN WITH SHOPIFY
                  </Button>
                  <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item>
                      By signing in you agree to our
                      <a href={"https://www.koyn.co/privacy"} variant="body2">
                        {" Privacy Policy "}
                      </a>
                       and
                      <a href={"https://www.koyn.co/terms"} variant="body2">
                        {" Terms of Service"}
                      </a>
                      .
                    </Grid>
                    <Grid item>
                      {error && <p>{error.message}</p>}
                    </Grid>
                  </Grid>
                </form>
              </div>
              <Box mt={8}>

              </Box>
            </Container>
      );
  }
}

const ShopifyAuthForm = withRouter(withFirebase(ShopifyAuthFormBase));

export default ShopifyAuthPage;

export { ShopifyAuthForm };
