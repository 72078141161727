import React from 'react';

import SubscribersItem from './SubscribersItem';

const SubscribersList = ({
  authUser,
  subscribers,
}) => (

  <table>
  <thead>
      <tr>
        <th>CUSTOMER</th>
        <th>ISSUED AMT.</th>
        <th>REDEEMED AMT.</th>
        <th>REVENUE</th>
        <th>CLV EST.</th>
      </tr>
    </thead>
    <tbody>
      {subscribers.map(subscriber => (
        <SubscribersItem
          authUser={authUser}
          key={subscriber.bid}
          subscriber={subscriber}
        />
      ))}
      <tr>
          <td>Jessica A.</td>
          <td>$5</td>
          <td>$5</td>
          <td>$58.58</td>
          <td>$1,300</td>
      </tr>
      <tr>
          <td>Lauren B.</td>
          <td>$5</td>
          <td>$0</td>
          <td>$65.00</td>
          <td>$400</td>
      </tr>
      <tr>
          <td>David F.</td>
          <td>$5</td>
          <td>$0</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
      <tr>
          <td>Chris G.</td>
          <td>$5</td>
          <td>$5</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
      <tr>
          <td>Jenna P.</td>
          <td>$5</td>
          <td>$0</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
      <tr>
          <td>Griffn A.</td>
          <td>$25</td>
          <td>$10</td>
          <td>$75.32</td>
          <td>$1,300</td>
      </tr>
      <tr>
          <td>Logan D.</td>
          <td>$5</td>
          <td>$0</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
      <tr>
          <td>Caroline F.</td>
          <td>$5</td>
          <td>$0</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
      <tr>
          <td>Beth H.</td>
          <td>$5</td>
          <td>$0</td>
          <td>$0</td>
          <td>$100</td>
      </tr>
    </tbody>
  </table>
);

export default SubscribersList;
